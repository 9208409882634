(<template>
  <article class="assignments-page">
    <section class="assignments-page__navigation">
      <div class="navigation__btns-cont">
        <button v-for="tab in tabsList"
                :key="tab.tab"
                :class="{'is-active': tab.isActive}"
                class="navigation__btn"
                @click="tab.onClick ? tab.onClick(tab.tab) : ''">{{ tab.title }}</button>
      </div>
      <div class="assignments-page__filters">
        <sk-select v-if="departmentsList.length"
                   :items-list="departmentsList"
                   :no-description="true"
                   :default-value="departmentsText"
                   :preselected-value="department"
                   class="assignments-page__filter"
                   @csvaluechanged="changeDepartment" />
        <assignment-search v-if="!userIsInterpreter"
                           class="assignments-page__filter"
                           @submitfilters="submitFilters" />
        <div class="navigation__calendar">
          <sk-select :default-value="yearText"
                     :preselected-value="year"
                     :items-list="years"
                     class="navigation__select"
                     @csvaluechanged="catchYearValue" />
          <sk-select :default-value="monthText"
                     :preselected-value="month"
                     :items-list="months"
                     class="navigation__select navigation__select--month"
                     @csvaluechanged="catchMonthValue" />
          <button class="sk-btn sk-btn--default navigation__calendar-btn"
                  @click="changeDate">{{ $gettext('View') }}</button>
        </div>
      </div>
    </section>
    <section v-if="dataLists"
             class="assignments-page__assignments">
      <assignments-list v-for="list in dataLists"
                        :key="list.title"
                        :assignments="list.assignments"
                        :title="list.title"
                        :filter="list.filter"
                        :show-offer="list.showOffer"
                        :current-page="list.currentPage"
                        :total-page="list.totalPage"
                        @sortby="list.sortingMethod"
                        @newpage="list.toNewPage"
                        @prev="toPrevPage(list.pageFieldName)"
                        @next="toNextPage(list.pageFieldName)" />
    </section>
  </article>
</template>)

<script>
  import {mapState, mapGetters, mapMutations} from 'vuex';
  import AssignmentsList from '@/components/assignment_components/all_assignments/AllAssignmentsList';
  import AssignmentSearch from '@/components/assignment_components/all_assignments/AssignmentSearch';

  export default {
    asyncData({store, route, moment}) {
      let promisesArr = [];
      const sort = +route.query.sort === 3 ? 'type' : +route.query.sort === 2 ? 'duration' : null;
      const sortConfirmedBy = +route.query.sortConfirmedBy === 3 ? 'type' : +route.query.sortConfirmedBy === 2 ? 'duration' : null;
      const sortAvailableBy = +route.query.sortAvailableBy === 3 ? 'type' : +route.query.sortAvailableBy === 2 ? 'duration' : null;
      const params = {
        sort,
        items: 10,
        page: +route.query.page,
        date: route.query.date || moment().format('YYYY-MM-DD')
      };

      // request for buyer search page
      if (store.getters['UserInfoStore/userIsNotInterpreter'] && route.query.searchQuery && !store.state.AllAssignmentsStore.searchAssignments) {
        const params = {
          searchQuery: route.query.searchQuery || null,
          items: 10,
          page: +route.query.page
        };
        promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getSearchAssignments', params)];
      } else {
        // requests for enterprise members
        if (store.getters['UserInfoStore/userIsEnterpriseMember']) {
          if (route.query.department) { params.department_id = route.query.department; }
          if (!store.state.selectsData.departments?.length) {
            promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'departments')];
          }
        }

        // requests for buyers
        if (store.getters['UserInfoStore/userIsNotInterpreter'] && route.query.view === 'current' && !store.state.AllAssignmentsStore.myAllAssignments) {
          promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getMyAllAssignments', params)];
        }

        // requests for both
        if (route.query.view === 'past' && !store.state.AllAssignmentsStore.pastAssignments) {
          promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getPastAssignments', params)];
        }
        if (route.query.view === 'declined' && !store.state.AllAssignmentsStore.canceledAssignments) {
          promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getHiddenAssignments', params)];
        }
      }

      // requests for interpreter
      if (store.getters['UserInfoStore/userIsInterpreter']) {
        if (route.query.view === 'current' && route.query.pageConfirmedBy && !store.state.AllAssignmentsStore.allBookedAssignments) {
          const confirmedParams = {...params};
          confirmedParams.sort = sortConfirmedBy;
          confirmedParams.page = +route.query.pageConfirmedBy;
          promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getAllBookedAssignments', confirmedParams)];
        }
        if (route.query.view === 'current' && route.query.pageAvailableBy && !store.state.AllAssignmentsStore.allAvailableAssignments) {
          const availableParams = {...params};
          availableParams.sort = sortAvailableBy;
          availableParams.page = +route.query.pageAvailableBy;
          promisesArr = [...promisesArr, store.dispatch('AllAssignmentsStore/getAllAvailableAssignments', availableParams)];
        }
        if (!store.state.TTSignaturesStore.documentsForSigning) {
          promisesArr = [...promisesArr, store.dispatch('TTSignaturesStore/getDocumentsForSigning')];
        }
        if (!store.state.s_DashboardStore.feedbackJobs) {
          promisesArr = [...promisesArr, store.dispatch('s_DashboardStore/getFeedbackJobs')];
        }
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'assignment-search': AssignmentSearch,
      'assignments-list': AssignmentsList
    },
    data() {
      return {
        department: this.$route.query.department || '',
        currentView: this.$route.query.view || 'current',
        currentPage: Number(this.$route.query.page) || 1,
        pageAvailableBy: Number(this.$route.query.pageAvailableBy) || 1,
        pageConfirmedBy: Number(this.$route.query.pageConfirmedBy) || 1,
        sorting: Number(this.$route.query.sort) || 1,
        sortAvailableBy: Number(this.$route.query.sortAvailableBy) || 1,
        sortConfirmedBy: Number(this.$route.query.sortConfirmedBy) || 1,
        routeDate: this.$route.query.date,
        routeSearchQuery: this.$route.query.searchQuery || '',
        year: this.$route.query.date ? this.$route.query.date.split('-')[0] : this.$moment().format('YYYY'),
        month: this.$route.query.date ? this.$route.query.date.split('-')[1] : this.$moment().format('MM')
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'enterpriseMemberJobsScope',
        'userIsInterpreter'
      ]),
      ...mapGetters({
        confirmedAssignments: 'AllAssignmentsStore/s_ConfirmedAssignments',
        availableAssignments: 'AllAssignmentsStore/s_AvailableAssignments',
        currentAssignments: 'AllAssignmentsStore/b_MyAllAssignments',
        pastAssignments: 'AllAssignmentsStore/PastAssignments',
        declinedAssignments: 'AllAssignmentsStore/HiddenAssignments'
      }),
      ...mapState('AllAssignmentsStore', {
        searchQuery: (state) => state.searchQuery,
        searchAssignmentsData: (state) => state.searchAssignments || {},
        currentAssignmentsData: (state) => state.myAllAssignments || {},
        confirmedAssignmentsData: (state) => state.allBookedAssignments || {},
        availableAssignmentsData: (state) => state.allAvailableAssignments || {},
        pastAssignmentsData: (state) => state.pastAssignments || {},
        declinedAssignmentsData: (state) => state.canceledAssignments || {}
      }),
      departmentsList() { return this.$store.state.selectsData.departments || []; },
      departmentsText() { return this.$gettext('Select department'); },
      searchAssignments() { return this.searchAssignmentsData.jobs || []; },
      searchAssignmentsPages() { return this.searchAssignmentsData.pages || 0; },
      currentAssignmentsPages() { return this.currentAssignmentsData.pages || 0; },
      confirmedAssignmentsPages() { return this.confirmedAssignmentsData.pages || 0; },
      availableAssignmentsPages() { return this.availableAssignmentsData.pages || 0; },
      pastAssignmentsPages() { return this.pastAssignmentsData.pages || 0; },
      declinedAssignmentsPages() { return this.declinedAssignmentsData.pages || 0; },
      yearText() { return this.$gettext('Year'); },
      monthText() { return this.$gettext('Month'); },
      isPastView() { return this.currentView == 'past'; },
      isAvailableView() { return this.currentView == 'current'; },
      isDeclinedView() { return this.currentView == 'declined'; },
      chosenDay() {
        if (this.year && this.month) {
          return `${this.year}-${this.month}-01`;
        }
        return this.$route.query.date || this.$moment().format('YYYY-MM-DD');
      },
      tabsList() {
        if (!this.searchQuery) {
          return [
            {
              tab: 'current',
              isActive: this.isAvailableView,
              title: this.$gettext('Current assignments'),
              onClick: this.setCurrentView
            },
            {
              tab: 'past',
              isActive: this.isPastView,
              title: this.$gettext('Past assignments'),
              onClick: this.setCurrentView
            },
            {
              tab: 'declined',
              isActive: this.isDeclinedView,
              title: this.userIsInterpreter ? this.$gettext('Declined/Hidden') : this.$gettext('Canceled'),
              onClick: this.setCurrentView
            }
          ];
        } else {
          return [
            {
              tab: 'all',
              isActive: true,
              title: this.$gettext('All assignments')
            }
          ];
        }
      },
      commonData() {
        return {
          filter: this.sorting,
          currentPage: this.currentPage,
          pageFieldName: 'currentPage',
          sortingMethod: this.standardSorting,
          toNewPage: this.toNewPage
        };
      },
      dataLists() {
        if (this.isAvailableView && this.userIsInterpreter) {
          return [{
            assignments: this.confirmedAssignments,
            title: this.$gettext('Confirmed assignments'),
            filter: this.sortConfirmedBy,
            showOffer: true,
            sortingMethod: this.changeConfirmedSort,
            currentPage: this.pageConfirmedBy,
            pageFieldName: 'pageConfirmedBy',
            totalPage: this.confirmedAssignmentsPages,
            toNewPage: this.toNewPageConfirmed
          }, {
            assignments: this.availableAssignments,
            title: this.$gettext('Available assignments'),
            filter: this.sortAvailableBy,
            showOffer: true,
            sortingMethod: this.changeAvailableSort,
            currentPage: this.pageAvailableBy,
            pageFieldName: 'pageAvailableBy',
            totalPage: this.availableAssignmentsPages,
            toNewPage: this.toNewPageAvailable
          }];
        }
        if (this.searchQuery) {
          return this.genDataForList({
            assignments: this.searchAssignments,
            title: this.$gettext('Your assignments'),
            totalPage: this.searchAssignmentsPages,
            filter: false
          });
        }
        if (this.isAvailableView && !this.userIsInterpreter) {
          return this.genDataForList({
            assignments: this.currentAssignments,
            title: this.$gettext('Your assignments'),
            totalPage: this.currentAssignmentsPages
          });
        }
        if (this.isPastView) {
          return this.genDataForList({
            assignments: this.pastAssignments,
            title: this.$gettext('Past assignments'),
            totalPage: this.pastAssignmentsPages
          });
        }
        if (this.isDeclinedView) {
          return this.genDataForList({
            assignments: this.declinedAssignments,
            title: this.userIsInterpreter ? this.$gettext('Declined / hidden assignments') : this.$gettext('Canceled assignments'),
            totalPage: this.declinedAssignmentsPages
          });
        }
      },
      assignments() {
        if (this.dataLists && this.dataLists.length) {
          for (const dataList of this.dataLists) {
            return dataList && dataList.assignments && dataList.assignments.length ? dataList.assignments : [];
          }
        }
        return [];
      },
      months() {
        let res = this.$helperData.getSelectMonthes(this).map((item) => {
          return {id: item.id, name: item.mobile};
        });
        if (this.$route.query.view === 'past' && +this.$moment().format('YYYY') == +this.year) {
          res = res.slice(0, this.$moment().month() + 1);
        }
        return res;
      },
      years() {
        const currentDate = new Date();
        const nextYear = String(currentDate.getFullYear() + 1);
        const res = this.$helperData.getSelectTTStartYears();

        res.push({id: nextYear, name: nextYear});
        return res.reverse();
      },
      isNotExistingPage() {
        return (this.currentPage != 1
          || this.pageAvailableBy != 1
          || this.pageConfirmedBy != 1)
          && this.assignments
          && this.assignments.length === 0;
      }
    },
    watch: {
      $route(newVal) {
        if (newVal) {
          this.setSearchQuery(newVal.query.searchQuery || '');
        }
      },
      searchQuery(newVal) {
        if (newVal) {
          this.department = '';
          this.year = '';
          this.month = '';
        }
      },
      enterpriseMemberJobsScope() {
        this.removeAllAssignmentsData();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    },
    methods: {
      ...mapMutations('AllAssignmentsStore', [
        'removeAllAssignmentsData',
        'removeSAllBookedAssignments',
        'removeSAllAvailableAssignments',
        'setSearchQuery'
      ]),
      genDataForList(additionalData) {
        return [{...this.commonData, ...additionalData}];
      },
      getFiltersParams() {
        const params = {};
        if (this.currentView) {
          params.view = this.currentView;
        }
        if (this.currentPage) {
          params.page = this.currentPage;
        }
        if (this.searchQuery) {
          params.searchQuery = this.searchQuery;
          return params;
        }
        if (this.department) {
          params.department = this.department;
        }
        if (this.routeDate) {
          params.date = this.routeDate;
        }
        if (this.sorting) {
          params.sort = this.sorting;
        }
        if (this.userIsInterpreter && this.isAvailableView) {
          if (this.sortAvailableBy) {
            params.sortAvailableBy = this.sortAvailableBy;
          }
          if (this.sortConfirmedBy) {
            params.sortConfirmedBy = this.sortConfirmedBy;
          }
          if (this.pageAvailableBy) {
            params.pageAvailableBy = this.pageAvailableBy;
          }
          if (this.pageConfirmedBy) {
            params.pageConfirmedBy = this.pageConfirmedBy;
          }
          return params;
        }
        return params;
      },
      submitFilters(removeDataCallback) {
        const params = this.getFiltersParams();
        removeDataCallback ? removeDataCallback() : this.removeAllAssignmentsData();
        this.$router.push(this.$makeRoute({path: 'all', query: params}));
        this.resetChosenDay();
      },
      clearFilters(notResetDate) {
        this.sorting = 1;
        this.sortConfirmedBy = 1;
        this.sortAvailableBy = 1;
        this.currentPage = 1;
        this.pageAvailableBy = 1;
        this.pageConfirmedBy = 1;
        this.setSearchQuery('');
        if (!notResetDate) {
          this.department = '';
          this.routeDate = '';
          this.year = this.$moment().format('YYYY');
          this.month = this.$moment().format('MM');
        }
      },
      handleNotExistingPage() {
        this.currentPage = 1;
        this.pageAvailableBy = 1;
        this.pageConfirmedBy = 1;
        this.submitFilters();
      },
      catchYearValue(value) {
        this.year = value;
      },
      catchMonthValue(value) {
        this.month = value;
      },
      setCurrentView(view) {
        this.currentView = view;
        this.clearFilters();
        this.submitFilters();
      },
      standardSorting(sort) {
        this.sorting = sort;
        this.submitFilters();
      },
      changeConfirmedSort(sort) {
        this.sortConfirmedBy = sort;
        this.submitFilters(this.removeSAllBookedAssignments);
      },
      changeAvailableSort(sort) {
        this.sortAvailableBy = sort;
        this.submitFilters(this.removeSAllAvailableAssignments);
      },
      changeDepartment(value) {
        this.department = value;
        this.setSearchQuery('');
        this.submitFilters();
      },
      changeDate() {
        this.routeDate = this.chosenDay;
        this.setSearchQuery('');
        this.submitFilters();
      },
      toPrevPage(currentPage) {
        this[currentPage] -= 1;
        this.submitFilters();
      },
      toNextPage(currentPage) {
        this[currentPage] += 1;
        this.submitFilters();
      },
      toNewPage(page) {
        const pageNumber = +page;
        if (this.currentPage !== pageNumber) {
          this.currentPage = pageNumber;
          this.submitFilters();
        }
      },
      toNewPageConfirmed(page) {
        this.pageConfirmedBy = +page;
        this.submitFilters();
      },
      toNewPageAvailable(page) {
        this.pageAvailableBy = +page;
        this.submitFilters();
      },
      resetChosenDay() {
        this.year = this.routeDate ? this.routeDate.split('-')[0] : this.$moment().format('YYYY');
        this.month = this.routeDate ? this.routeDate.split('-')[1] : this.$moment().format('MM');
      }
    },
    mounted() {
      this.setSearchQuery(this.routeSearchQuery);
      if (this.dataPromise) {
        this.dataPromise.then(() => {
          if (this.isNotExistingPage) {
            this.handleNotExistingPage();
          }
        });
      }
      if (!this.$cookieManager.getCookie('lastUsedType') || this.$cookieManager.getCookie('lastUsedType') !== 'interpretation') {
        this.$cookieManager.setCookie('lastUsedType', 'interpretation');
      }
    },
    beforeDestroy() {
      this.removeAllAssignmentsData();
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('s_DashboardStore/clearFeedbackJobs');
      next();
    }
  };
</script>

<style>
  .assignments-page__navigation .sk-date-picker {
    width: auto;
    border: none;
  }

  .assignments-page__navigation .sk-date-picker__value {
    width: auto;
    min-width: 100px;
    padding-right: 25px;
    background-color: transparent;
    font-weight: bold;
  }

  .assignments-page__navigation .sk-date-picker__value::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(43deg);
  }

  .assignments-page__navigation .sk-date-picker__value::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(-43deg);
  }

  .assignments-page__navigation .is-active .sk-date-picker__value::before {
    transform: rotate(-43deg);
  }

  .assignments-page__navigation .is-active .sk-date-picker__value::after {
    transform: rotate(43deg);
  }

  .assignments-page__navigation .sk-date-picker__value:active {
    width: auto;
    padding-right: 25px;
    background-color: transparent;
  }

  .assignments-page__navigation .sk-date-picker__calendar.sk-date-picker__calendar {
    left: -100px;
  }

  .assignments-page__navigation .sk-select {
    background-color: white;
  }

  @media (max-width: 767px) {
    .assignments-page__navigation .sk-date-picker__calendar.sk-date-picker__calendar {
      left: 0;
    }
  }
</style>

<style scoped>
  .assignments-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100%;
    padding: 15px 15px 80px 15px;
    background-color: #f4f5f7;
  }

  .assignments-page__filters {
    display: flex;
    margin: 5px 5px 0 auto;
  }

  .assignments-page__filter {
    width: 170px;
    margin: 0 5px 5px;
  }

  .assignments-page__navigation,
  .assignments-page__assignments {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 950px;
  }

  .assignments-page__navigation {
    justify-content: space-between;
    margin-bottom: -10px;
  }

  .navigation__btns-cont {
    margin-right: auto;
  }

  .navigation__btn {
    position: relative;
    width: auto;
    margin: 0 8px;
    padding: 8px 5px 4px;
    font-weight: bold;
    font-size: 18px;
  }

  .navigation__btn:focus {
    box-shadow: var(--default-focus-shadow);
  }

  .navigation__btn.is-active {
    border-bottom: 5px solid #ff5b24;
    border-bottom: 5px solid var(--color-secondary);
    color: #ff5b24;
    color: var(--color-secondary);
  }

  .navigation__calendar {
    display: flex;
    width: auto;
    margin: 0 5px 5px;
  }

  .navigation__calendar-btn {
    width: auto;
    padding: 0 11px;
  }

  .navigation__select {
    width: 80px;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .navigation__select--month {
    width: 110px;
  }

  @media (max-width: 767px) {
    .assignments-page {
      padding: 15px 0 0;
    }

    .navigation__btn {
      margin: 0 5px 0 0;
      font-size: 14px;
    }

    .assignments-page__filters {
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      margin: 10px 0 5px;
    }

    .navigation__calendar-btn {
      width: auto;
      margin-left: auto;
    }
  }

  @media (max-width: 500px) {
    .assignments-page__filter,
    .navigation__calendar {
      width: 100%;
      max-width: none;
    }
  }
</style>
