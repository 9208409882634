(<template>
  <section class="assignments-page__cont">
    <div class="assignments-page__cont-wrapper">
      <div class="assignments-page__cont-title">
        <h3 class="assignments-page__cont-title-text">{{ title }}</h3>
        <secondary-select v-if="filter"
                          :items-list="filters"
                          :description="selectDescription"
                          :filters="true"
                          :autofocus="true"
                          :secandary-value="true"
                          :preselected-value="filter"
                          class="assignments-page__filters"
                          @csvaluechanged="emitSortBy" />
      </div>
      <assignments-list :assignments-list="assignments"
                        :show-offer="showOffer" />
    </div>
    <sk-pagination v-if="totalPage > 1"
                   :current="+currentPage"
                   :total="+totalPage"
                   class="assignments-page__pagination"
                   @prev="toPrevPage"
                   @next="toNextPage"
                   @newpage="toNewPage" />
  </section>
</template>)

<script>
  import AssignmentsList from '@/components/assignment_components/dashboard/AssignmentsList';

  export default {
    components: {
      'assignments-list': AssignmentsList
    },
    props: {
      assignments: {
        type: Array,
        default: () => []
      },
      showOffer: {
        type: Boolean
      },
      title: {
        type: String,
        default: ''
      },
      filter: {
        type: [String, Number, Boolean],
        default: 1
      },
      currentPage: {
        type: [String, Number],
        default: 0
      },
      totalPage: {
        type: [String, Number],
        default: 0
      }
    },
    computed: {
      selectDescription() { return this.$gettext('Sort by'); },
      filters() {
        return [
          {id: 1, name: this.$gettext('Date')},
          {id: 2, name: this.$gettext('Time')},
          {id: 3, name: this.$gettext('Assignment type')}
        ];
      }
    },
    methods: {
      emitSortBy(value) {
        this.$emit('sortby', value);
      },
      toPrevPage() {
        this.$emit('prev');
      },
      toNewPage(page) {
        this.$emit('newpage', page);
      },
      toNextPage() {
        this.$emit('next');
      }
    }
  };
</script>

<style>
  @media (min-width: 768px) {
    .assignments-page__cont .secandary-select__value-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .assignments-page__cont .secandary-select__description {
      width: auto;
      padding-right: 5px;
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    .assignments-page__cont .secandary-select__value {
      display: none;
    }
  }
</style>

<style scoped>
  .assignments-page__cont {
    display: flex;
    flex-grow: 2;
    flex-basis: 300px;
    flex-direction: column;
    width: auto;
    max-width: 950px;
    min-height: 100%;
  }

  .assignments-page__cont-wrapper {
    margin: 10px 8px;
    padding: 20px;
    padding-top: 0;
    border-radius: 3px;
    background-color: #fff;
  }

  .assignments-page__cont-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .assignments-page__cont-title-text {
    padding-top: 12px;
    font-weight: normal;
    font-size: 18px;
  }

  .assignments-page__filters {
    min-width: 135px;
    padding-top: 7px;
  }

  .assignments-page__pagination {
    margin: 10px auto;
  }

  @media (max-width: 767px) {
    .assignments-page__cont {
      width: 100%;
      max-width: initial;
      margin: 0;
      margin-top: 10px;
    }

    .assignments-page__cont-wrapper {
      margin: 0;
    }

    .assignments-page__filters {
      padding-top: 0;
    }
  }
</style>
