(<template>
  <div class="assignment-search">
    <div class="assignment-search__wrapper">
      <sk-input :placeholder="searchText"
                :preselected-value="searchQuery"
                :input-icon="'search'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                class="assignment-search__search-field"
                @fieldvaluechanged="setSearchQuery" />
      <button v-if="searchQuery"
              class="assignment-search__btn"
              @click="resetFilter"></button>
    </div>
  </div>
</template>)

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import AssignmentSearchPrototype from '@/components/shared_components/form_fields/AssignmentSearchPrototype';

  export default {
    extends: AssignmentSearchPrototype,
    data() {
      return {
        delay: 1000
      };
    },
    computed: {
      ...mapState('AllAssignmentsStore', {
        searchQuery: (state) => state.searchQuery
      })
    },
    methods: {
      ...mapActions({
        cancelSearch: 'AllAssignmentsStore/cancelSearchAssignments'
      }),
      ...mapMutations({
        setSearchQueryMutation: 'AllAssignmentsStore/setSearchQuery',
        setAssignments: 'AllAssignmentsStore/setSearchAssignments'
      }),
      search() {
        this.$emit('submitfilters');
      },
      resetFilter() {
        this.setSearchQueryMutation('');
        this.setAssignments([]);
        this.$emit('submitfilters');
      }
    }
  };
</script>

<style scoped>
  .assignment-search {
    margin-left: 10px;
  }

  .assignment-search__wrapper {
    position: relative;
  }

  .assignment-search__search-field {
    margin-bottom: 0;
    background-color: white;
  }

  .assignment-search__btn {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 15px;
    height: 15px;
    transform: translate(0, -50%);
  }

  .assignment-search__btn::before,
  .assignment-search__btn::after {
    content: ' ';
    position: absolute;
    top: 0;
    width: 1px;
    height: 15px;
    background-color: #a7abbd;
  }

  .assignment-search__btn::before {
    transform: rotate(45deg);
  }

  .assignment-search__btn::after {
    transform: rotate(-45deg);
  }

  @media (max-width: 767px) {
    .assignment-search {
      max-width: 235px;
      margin: 20px 10px 0;
    }
  }
</style>
